import styled from "styled-components"

export const FooterStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  border-top: 2px solid #8da3c2;
  color: #5da6ad;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`
export const Contato = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;

  h3 {
    font-size: 24px;
  }

  p {
    margin: 10px 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
  }

  @media (max-width: 425px) {
    p {
      font-size: 15px;
    }
  }
`

export const Social = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 30vw;
  margin-bottom: 30px;

  .icon {
    font-size: 30px;
    color: #000;
    color: #5da6ad;
  }

  .icon:hover {
    color: #424d87;
  }

  @media (max-width: 425px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
`
