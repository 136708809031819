import styled from "styled-components"

export const HeaderStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 500px;
    top: 30px;
    margin-top: 50px;
  }

  h2 {
    font-size: 40px;
    font-weight: normal;
    color: #9c592d;
    margin-bottom: 40px;
  }

  @media (max-width: 425px) {
    h2 {
      font-size: 25px;
      text-align: center;
    }
  }
`

export const Navbar = styled.div`
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 80px 0;
  background-color: #e5e5e5;

  a {
    text-decoration: none;
    color: #6f9de3;
    font-size: 30px;
  }

  a:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 2px -2px #5da6ad;
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    height: 200px;
    margin: 20px 0;

    a {
      font-size: 15px;
    }
  }
`
