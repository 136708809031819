import React from "react"

import { Link } from "gatsby"
import { HeaderStyle, Navbar } from "./style"

const Header = () => {
  return (
    <div>
      <HeaderStyle>
        <img src="/images/Mau-Logo.png" className='logo'/>
        <h2>Desenvolvimento Humano & Negócios</h2>
        <Navbar>
            <Link to="/">Página Inicial</Link>
            <Link to="/sobre">Sobre</Link>
            <Link to="/servicos">Serviços</Link>
            <Link to="/curriculo">Currículo</Link>
            <a href="https://mauriciolambiasi.blogspot.com/" target="_blank">
              Blog
            </a>
            <Link to="/contato">Contato</Link>
        </Navbar>
      </HeaderStyle>
    </div>
  )
}

export default Header
